import React from "react";
import { graphql, PageProps } from "gatsby";

import { useStoryblok } from "@/base/lib/storyblok";
import Layout from "@/components/layouts/Layout";
import CommonPostSection from "@/components/sections/CommonPostSection";

interface PageData {
  layout: StoryblokStory;
  featuredNews: {
    edges: {
      node: StoryblokStory;
    }[];
  };
}

export default function Post({
  pageContext,
  location,
  data,
}: PageProps<PageData, PostContext>) {
  const { lang, languages, story, categories, category } = pageContext;
  const { layout, featuredNews } = data;
  const pageStory = useStoryblok<CommonPostType>(story, location);
  const { content } = pageStory;
  const { metadata } = content;
  const categoryLabel = categories.find(({ node }) => node.value === category)
    ?.node.name;
  const parsedLayout =
    layout && (JSON.parse(layout.content) as CommonLayoutType);
  const { header, footer } = parsedLayout || {};
  const parsedFeaturedNews = featuredNews.edges.map(({ node }) => ({
    ...node,
    content: JSON.parse(node.content) as CommonPostType,
  }));

  if (story == null) {
    return (
      <div>
        <h3>Editor page</h3>
      </div>
    );
  }

  return (
    <Layout
      header={header && header[0]}
      footer={footer && footer[0]}
      seo={{
        ...metadata,
        og_type: "article",
        og_image: metadata?.og_image || content?.post_hero?.image,
      }}
      siteName={story.name}
      pathname={location.pathname}
      hash={location.hash}
      languages={languages}
      lang={lang}
    >
      <CommonPostSection
        path={location.href}
        lang={lang}
        featuredNews={parsedFeaturedNews}
        postContent={content}
        categoryLabel={categoryLabel}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query PostQuery($uuid: String!, $category: String!) {
    layout: storyblokEntry(field_component: { eq: "common_layout" }) {
      content
    }
    featuredNews: allStoryblokEntry(
      filter: {
        uuid: { ne: $uuid }
        field_component: { eq: "common_post" }
        field_post_category_string: { eq: $category }
      }
      sort: { order: DESC, fields: [field_post_date_string] }
      limit: 4
    ) {
      edges {
        node {
          uuid
          content
          slug
          full_slug
        }
      }
    }
  }
`;
